import {FaEthereum} from "react-icons/fa";
import {formatEther} from "ethers/lib/utils";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import {twMerge} from "tailwind-merge";
import useId from "@/hooks/useId";
import {Tooltip as ReactTooltip} from "react-tooltip";
import React from "react";
import {formatValue} from "@/util/converters";
import classNames from "classnames";

export interface Props {
  value: bigint;
  valueForPercent?: bigint;
  original?: bigint | undefined;
  fractions?: number | undefined;
  className?: string | undefined;
  valueClassName?: string | undefined;
  percentClassName?: string | undefined;
  showPlus?: boolean;
  id?: string | undefined;
  ethPrice?: number;
  iconSize?: number;
  showInUsd?: boolean;
}

const computeFractions = (value: bigint) => {
  const val = Math.abs(Number(formatEther((value))));
  if (val > 10) {
    return 1;
  } else if (val > 1) {
    return 3;
  } else if (val > 0.1) {
    return 3;
  } else if (val > 0.01) {
    return 4;
  } else if (val > 0.0001) {
    return 5;
  }
  return 6;
}

const computeFractionsUsd = (usdValue: number) => {
  const valueToCheck = Math.abs(usdValue);
  if (valueToCheck > 100) {
    return 0;
  } else if (valueToCheck > 10) {
    return 1;
  } else if (valueToCheck > 1) {
    return 2;
  } else if (valueToCheck > 0.1) {
    return 3;
  }
  return 4;
}

export const EthValue = ({ id, value, valueForPercent, original, fractions, className, showPlus, valueClassName, ethPrice, percentClassName, iconSize, showInUsd }: Props) => {
  const divId = useId(id);

  const fractionsToUse = fractions || computeFractions(value);
  const valueToUse = valueForPercent !== undefined ? valueForPercent : value;
  let percentValue: number | undefined;
  if (original) {
    const denom = 1000;
    percentValue = (Number(valueToUse * BigInt(denom) / original) / denom - 1) * 100;
  }

  const renderPercentValue = () => {
    if (percentValue === undefined) {
      return null;
    }
    return (
      <span className={classNames("flex items-center text-xs", {
        "text-neutral-content": percentValue === 0,
        "text-call": percentValue > 0,
        "text-put": percentValue < 0
      })}>
        {percentValue === 0 ? <GoTriangleDown/> : percentValue > 0 ? <GoTriangleUp/> : <GoTriangleDown/>}
        {parseFloat(Math.abs(percentValue).toFixed(fractionsToUse - 1))}%
      </span>
    )
  }

  if (showInUsd && ethPrice) {
    const usdValue = Number(formatEther(value)) * ethPrice;
    return (
      <div id={divId} className={twMerge("flex flex-row gap-1 items-center", className || '')}>
        <span>
          {showPlus && usdValue > 0 ? "+" : ""}
          {usdValue.toLocaleString([], {
            currency: 'USD',
            style: 'currency',
            maximumFractionDigits: computeFractionsUsd(usdValue)
          })}
        </span>
        {renderPercentValue()}
      </div>
    )
  }

  return (
    <>
      <div id={divId} className={twMerge("flex flex-row gap-1 items-center", className || '')}>
        <span className={twMerge("flex flex-row items-center gap-1", valueClassName || '')}>
          <FaEthereum size={iconSize || 12} />
          {showPlus && value > 0 ? "+" : ""}{Number(formatEther(value)).toLocaleString([], {maximumFractionDigits: fractionsToUse })}
        </span>
        {renderPercentValue()}
      </div>
      {
        ethPrice &&
        <ReactTooltip
          anchorSelect={`#${divId}`}
          id={`eth_value_tooltip_${divId}`}
          place="top"
          noArrow
          className="z-50 text-lg font-bold"
          content={ethPrice ? `$ ${formatValue(BigInt(value) * BigInt(ethPrice.toFixed(0)), 2).toLocaleString()}` : ''}
          style={{ backgroundColor: "#3b485f", color: "#98a2b3" }}
        />
      }
    </>
  )
}